<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="profile-link">
        <div class="c-avatar">
          <img src="img/avatars/6.jpg" class="c-avatar-img " />
        </div>
        <span class="mx-2">{{user_name}}</span>
        <span class="mx-1"><CIcon name="cil-caret-bottom"/></span>
      </CHeaderNavLink>
    </template>
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-bell" /> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader> -->
    <CDropdownItem @click="my_profile()"> <CIcon name="cil-user" /> Profile </CDropdownItem>
  
    <!-- <CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem> -->
    <CDropdownItem v-on:click="logout()"> <CIcon name="cil-lock-locked" /> Logout </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      user_name:"",
      user_role:""
    };
  },
  methods:{
    my_profile(){
      if(this.user_role == '8de886dd-12e5-4b02-b812-187639513779'){
        this.$router.push('/teacher_profile');
      }else{
        this.$router.push('/profile');
      }
      
    },
    logout(){
      localStorage.removeItem('user_id');
      localStorage.clear();
      this.$session.destroy();
      this.$session.clear();
     // this.$router.push("/");
     window.location.href = '/';
    }

  },
  created(){
    this.user_name = localStorage.getItem('user_name');
    this.user_role = localStorage.getItem('user_role_id');

  }
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
