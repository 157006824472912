<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    colorScheme="light"
    class="c-sidebar-mahavidya"
  >
    <CSidebarBrand class="d-md-down-none logo-box" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="95"
        viewBox="0 0 556 234"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction  v-if="this.role == '8de886dd-12e5-4b02-b812-187639513779'" flat :content-to-render="$options.teachernav"/>
    <CRenderFunction  v-if="this.role == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9'" flat :content-to-render="$options.schoolnav"/>
    <CRenderFunction  v-if="this.role == '8290f938-b20a-42e3-c201-08d8f3241655'" flat :content-to-render="$options.studentnav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
 import studentnav from "./_nav_student";
 import teachernav from "./_nav_teacher";
 import schoolnav from "./_nav_school";
 import supernav from "./_nav_super";

export default {
  name: "TheSidebar",
  data(){
    return {
      role:""
    }
  },
  studentnav,
  teachernav,
  schoolnav,
  supernav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  created(){
    this.role = localStorage.getItem('user_role_id');
  }
};
</script>
