export default [
    {
      _name: "CSidebarNav",
      _children: [
        {
          _name: "CSidebarNavDropdown",
          name: "Dashboard",
          route: "/dashboard",
          icon: "cil-speedometer",
          items: [
            {
              name: "Dashboard",
              to: "/dashboard",
            },
            {
              name: "Districts",
              to: "/dashboard/districts",
            },
            {
              name: "School",
              to: "/dashboard/school",
            },
            {
              name: "Teacher",
              to: "/dashboard/teacher",
            },
            {
              name: "Student",
              to: "/dashboard/student",
            },
          ],
        },
  
        {
          _name: "CSidebarNavItem",
          name: "User Study",
          to: "/user-study",
          icon: "cil-graph",
        },
        {
          _name: "CSidebarNavItem",
          name: "Launch a Study",
          to: "/launch-a-study",
          icon: "cil-paper-plane",
        },
  
        {
          _name: "CSidebarNavDropdown",
          name: "View Books",
          route: "/view-books",
          icon: "cil-book",
          items: [
            {
              name: "Upload Study Materials",
              to: "/study/uploadstudy1",
            },
            {
              name: "Library",
              to: "/view-books/library",
            },
            {
              name: "Completed",
              to: "/view-books/completed",
            },
            {
              name: "Favorite",
              to: "/view-books/favorite",
            },
          ],
        },
  
        {
          _name: "CSidebarNavDropdown",
          name: "My Account",
          route: "/my-account",
          icon: "cil-user",
          items: [
            {
              name: "Profile",
              to: "/my-account/profile",
            },
            {
              name: "Settings",
              to: "/my-account/settings",
            },
          ],
        },
  
        {
          _name: "CSidebarNavTitle",
          _children: ["Auth"],
        },
        {
          _name: "CSidebarNavItem",
          name: "Login",
          to: "/auth/login",
          icon: "cil-arrow-right",
        },
        {
          _name: "CSidebarNavItem",
          name: "Register",
          to: "/auth/register",
          icon: "cil-at",
        },
        {
          _name: "CSidebarNavTitle",
          _children: ["Theme"],
        },
        {
          _name: "CSidebarNavItem",
          name: "Colors",
          to: "/theme/colors",
          icon: "cil-drop",
        },
        {
          _name: "CSidebarNavItem",
          name: "Typography",
          to: "/theme/typography",
          icon: "cil-pencil",
        },
        {
          _name: "CSidebarNavTitle",
          _children: ["Components"],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Base",
          route: "/base",
          icon: "cil-puzzle",
          items: [
            {
              name: "Breadcrumbs",
              to: "/base/breadcrumbs",
            },
            {
              name: "Cards",
              to: "/base/cards",
            },
            {
              name: "Carousels",
              to: "/base/carousels",
            },
            {
              name: "Collapses",
              to: "/base/collapses",
            },
            {
              name: "Forms",
              to: "/base/forms",
            },
            {
              name: "Jumbotrons",
              to: "/base/jumbotrons",
            },
            {
              name: "List Groups",
              to: "/base/list-groups",
            },
            {
              name: "Navs",
              to: "/base/navs",
            },
            {
              name: "Navbars",
              to: "/base/navbars",
            },
            {
              name: "Paginations",
              to: "/base/paginations",
            },
            {
              name: "Popovers",
              to: "/base/popovers",
            },
            {
              name: "Progress Bars",
              to: "/base/progress-bars",
            },
            {
              name: "Switches",
              to: "/base/switches",
            },
            {
              name: "Tables",
              to: "/base/tables",
            },
            {
              name: "Tabs",
              to: "/base/tabs",
            },
            {
              name: "Tooltips",
              to: "/base/tooltips",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Buttons",
          route: "/buttons",
          icon: "cil-cursor",
          items: [
            {
              name: "Buttons",
              to: "/buttons/standard-buttons",
            },
            {
              name: "Button Dropdowns",
              to: "/buttons/dropdowns",
            },
            {
              name: "Button Groups",
              to: "/buttons/button-groups",
            },
            {
              name: "Brand Buttons",
              to: "/buttons/brand-buttons",
            },
          ],
        },
        {
          _name: "CSidebarNavItem",
          name: "Charts",
          to: "/charts",
          icon: "cil-chart-pie",
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Icons",
          route: "/icons",
          icon: "cil-star",
          items: [
            {
              name: "CoreUI Icons",
              to: "/icons/coreui-icons",
              badge: {
                color: "info",
                text: "NEW",
              },
            },
            {
              name: "Brands",
              to: "/icons/brands",
            },
            {
              name: "Flags",
              to: "/icons/flags",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Notifications",
          route: "/notifications",
          icon: "cil-bell",
          items: [
            {
              name: "Alerts",
              to: "/notifications/alerts",
            },
            {
              name: "Badges",
              to: "/notifications/badges",
            },
            {
              name: "Modals",
              to: "/notifications/modals",
            },
          ],
        },
        {
          _name: "CSidebarNavItem",
          name: "Widgets",
          to: "/widgets",
          icon: "cil-calculator",
          badge: {
            color: "primary",
            text: "NEW",
            shape: "pill",
          },
        },
        {
          _name: "CSidebarNavDivider",
          _class: "m-2",
        },
        {
          _name: "CSidebarNavTitle",
          _children: ["Extras"],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Pages",
          route: "/pages",
          icon: "cil-star",
          items: [
            {
              name: "Login",
              to: "/pages/login",
            },
            {
              name: "Register",
              to: "/pages/register",
            },
            {
              name: "Error 404",
              to: "/pages/404",
            },
            {
              name: "Error 500",
              to: "/pages/500",
            },
          ],
        },
      ],
    },
  ];
  